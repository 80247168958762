/* ===================== SERVICE ======================*/
.service-area {
  padding-top: 50px;
  padding-bottom: 70px;
}

.service-box {
  text-align: center;
  padding: 50px 0;
  position: relative;
  z-index: 1;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  height: 400px;
  width: 400px;
  word-break: break-all;
  margin: 30px 0;
}

.service-box:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(193, 149, 43, 0.3);
  z-index: -1;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.service-box > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.service-box:hover:after,
.service-box:hover > img {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.service-box-modified > .serviceImg {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.service-text {
  padding-top: 60%;
  margin-top: 30px;
  word-break: break-all;
}

.service-box-modified .service-text h3 {
  color: #fff;
  background: rgba(193, 149, 43, 0.7);
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 2px;
  padding: 5px 0;
}

.service-text p,
.service-text h3 {
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.service-box-modified .service-text p {
  opacity: 0;
}
.service-box:hover .service-text {
  padding-top: 0;
  margin-top: 0;
}
.service-box:hover .service-text p,
.service-box:hover .service-text h3 {
  background: none;
  opacity: 1;
  color: #fff;
}

.service-text .btn-border {
  border-width: 1px;
  margin-top: 15px;
}

.service-box:hover .btn-border {
  color: #fff;
  border-color: #fff;
}

.service-page-area {
  padding-top: 70px;
  padding-bottom: 100px;
}

.service-page-area .service-box {
  margin-top: 30px;
  margin-bottom: 0;
}
.service-icon i {
  font-size: 40px;
  color: #fff;
}

.title-gold {
  color: #c1952b;
}

/*Responsive*/
@media (max-width: 767px) {
  .service-area {
    padding-top: 0;
  }
  .service-page-area {
    padding-top: 70px;
  }
  .service-text h3 {
    font-size: 30px;
  }
}
