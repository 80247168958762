/* ===================== PROJECT SINGLE ======================*/
.project-details-area {
  margin-bottom: 80px;
}
.projects-page-area .col-md-5:nth-child(2n + 2) {
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
}

.projects-page-area .project-box {
  margin-bottom: 100px;
}

.projects-page-area {
  padding-bottom: 50px;
}

.project-details-top {
  position: relative;
  overflow: hidden;
}

.project-details-top-img {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  text-align: center;
}
.project-details-top-img div {
  width: 80%;
}

.project-details-top-img img {
  width: 100%;
}
.slick-slider-div-wrapper {
  background: #eee;
  padding: 10px 20px;
  padding-bottom: 40px;
}
.project-details-top-box h3,
.project-details-top-box h5 {
  color: #fff;
}

.outerButton,
.innerButton {
  background: #8d8d8e;
  border: #8d8d8e solid 1px;
  border-radius: 0;
  height: 45px;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background: #c1952b !important;
  border: #c1952b solid 1px !important;
}
.outerButton:checked,
.innerButton:checked {
  background: #c1952b !important;
  border: #c1952b solid 1px !important;
}

.outerButton:hover,
.innerButton:hover {
  background: #c1952b !important;
  border: #c1952b solid 1px !important;
}
.socialShare {
  display: inline-block;
  line-height: 43px;
  color: #fff;
  padding: 0 15px;
  font-size: 14px;
  margin: 0 0.5px;
  background-color: #393f8b;
  border: #c1952b solid 1px;
}
.project-details-top-box p {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
}

.project-details-top-box {
  width: 40%;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: center;
  background: #3b3c40;
  padding-top: 20px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.project-details-top:hover .project-details-top-box {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.project-details-top:hover .project-details-top-img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.project-details-top-box-text {
  padding: 20px 0;
}

.project-details-share ul li {
  display: inline-block;
  margin: 0 3px;
}

.project-details-top-text {
  margin: 30px 0;
}

.project-details-top-text h2 {
  font-size: 50px;
  margin-bottom: 20px;
}

.project-details-box {
  margin-bottom: 0;
}

.project-details-box img {
  width: 100%;
}

.project-details-box p {
  position: absolute;
  left: 20px;
  bottom: 0;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  letter-spacing: 5px;
}

.project-details-box-meta-text {
  padding: 30px;
  background: rgba(249, 249, 249, 0.76);
}

.project-details-box-meta-text a {
  font-weight: 500;
}
.project-details li {
  list-style: disc inside;
}

.project-overview-box {
  text-align: center;
  /* width: 100px; */
  /* height: 150px; */
  text-align: center;
  margin: 15px 0;
}

.details-box {
  margin-top: 30px;
}

.project-overview {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  /* margin: 50px 0; */
  padding-top: 20px;
  /* border: 1px solid #111; */
  background: #eee;
}

.blueprints-overview {
  padding-top: 20px;
}
/* .project-overview div {
  border: 1px solid #111;
} */
.project-overview-box i {
  width: 50px;
  /* border: 1px solid #111 ; */
  font-size: 42px;
  /* padding: 5px; */
  /* margin-bottom: 20px; */
}
/* .project-overview-box i:hover {
  font-size: 70px;
  padding: 20px;
  width: auto;
} */

.project-overview-box h5 {
  font-size: 22px;
}

.project-overview-box p {
  font-weight: 500;
  color: #000;
  font-size: 24px;
}
.project-details li {
  font-size: 18px;
  padding: 10px;
}
.slick-dots-modified {
  position: relative;
  /* bottom: -110px;
  display: block; */
}
/* .slick-dots-modified li {
  width: 100px;
  height: 100px;
} */
.slick-slider-container {
  margin-bottom: 110px;
  /* width: 70%; */
  margin: 0 auto;
}
.perkIcon {
  color: #ac2c3d;
}
.service-box {
  text-align: center;
  padding: 50px 0;
  position: relative;
  z-index: 1;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  height: 400px;
  width: 400px;
  word-break: keep-all;
  /* word-wrap: normal; */
}

.service-box:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(119, 119, 34, 0.3);
  z-index: -1;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.service-box > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.service-box:hover:after,
.service-box:hover > img {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.service-box-modified > .landmarkImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.service-text {
  margin-top: 30px;
  word-break: keep-all;
  padding-top: 67%;
}

.service-box-modified .service-text h3 {
  color: #fff;
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 2px;
}

.service-text p,
.service-text h3 {
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.service-box-modified .service-text p {
  padding: 0 30px;
  opacity: 0;
}
.service-box:hover .service-text {
  margin-top: 0;

  padding-top: 0;
}
.service-box:hover .service-text p,
.service-box:hover .service-text h3 {
  background: none;
  opacity: 1;
  color: #3a3a3c;
}

.service-text .btn-border {
  border-width: 1px;
  margin-top: 15px;
}

.service-box:hover .btn-border {
  color: #fff;
  border-color: #fff;
}

.service-page-area {
  padding-top: 70px;
  padding-bottom: 100px;
}

.service-page-area .service-box {
  margin-top: 30px;
  margin-bottom: 0;
}

/*Responsive*/

/*Responsive*/
@media (max-width: 767px) {
  .project-box p {
    font-size: 14px;
    left: -110px;
    /* bottom: 135px; */
    /* letter-spacing: 4px; */
  }
  .project-box h3 {
    top: 0px;
    left: 0px;
    /* letter-spacing: 2px; */
    font-size: 19px;
  }

  .project-box:hover .project-zoom {
    -webkit-transform: scale(1) translateX(-50%, -50%);
    transform: scale(1) translateX(-50%, -50%);
  }
  .projects-area {
    padding-bottom: 70px;
  }
  .projects-page-area .col-md-5:nth-child(2n + 2) {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  .projects-page-area .project-box {
    margin-bottom: 30px;
  }
  .slick-dots-modified {
    position: relative;
    /* bottom: -80px;
    display: block; */
  }
  /* .slick-dots-modified li {
    width: 20px;
    height: 20px;
  } */
}
@media (max-width: 1200px) {
  .slick-dots-modified {
    position: relative;
    /* bottom: -80px;
    display: block; */
  }
  /* .slick-dots-modified li {
    width: 70px;
    height: 70px;
  } */
}

@media (max-width: 575px) {
  .projects-page-area .col-md-5:nth-child(2n + 2) {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  .projects-page-area .project-box {
    margin-bottom: 30px;
  }
  .projects-page-area {
    padding-bottom: 70px;
  }
  .slick-dots-modified {
    position: relative;
    /* bottom: -50px;
    display: block; */
  }
  /* .slick-dots-modified li {
    width: 40px;
    height: 40px;
  } */
  .slick-slider-container {
    margin-bottom: 50px;
  }
}
@media (max-width: 991px) {
  .slick-dots-modified {
    position: relative;
    /* bottom: -60px;
    display: block; */
  }
  /* .slick-dots-modified li {
    width: 40px;
    height: 40px;
  } */
}

@media (min-width: 768px) and (max-width: 991px) {
  .project-box p {
    font-size: 16px;
    left: -110px;
    /* bottom: 150px; */
    /* letter-spacing: 6px; */
  }
  .project-box h3 {
    top: 0px;
    left: 20px;
    /* letter-spacing: 2px; */
    font-size: 24px;
  }

  .project-box:hover .project-zoom {
    -webkit-transform: scale(1) translateX(-50%, -50%);
    transform: scale(1) translateX(-50%, -50%);
  }
  .projects-area {
    padding-bottom: 70px;
  }
}
@media (width: 1024px) {
  .project-box p {
    font-size: 20px;
    left: -110px;
    /* bottom: 150px; */
    /* letter-spacing: 6px; */
  }
  .project-box h3 {
    top: 0px;
    left: 20px;
    /* letter-spacing: 2px; */
    font-size: 30px;
  }

  .project-box:hover .project-zoom {
    -webkit-transform: scale(1) translateX(-50%, -50%);
    transform: scale(1) translateX(-50%, -50%);
  }
}
@media (max-width: 767px) {
  .service-area {
    padding-top: 0;
  }
  .service-page-area {
    padding-top: 70px;
  }
  .service-text h3 {
    font-size: 30px;
  }
}
