/* ===================== SERVICE ======================*/
.service-area {
  padding-top: 50px;
  padding-bottom: 70px;
}

.service-box {
  text-align: center;
  padding: 50px 0;
  position: relative;
  z-index: 1;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

.service-box:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(193, 149, 43, 0.3);
  z-index: -1;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.service-box > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.service-box:hover:after,
.service-box:hover > img {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/* .service-text {
  margin-top: 30px;
} */

.service-text h3 {
  color: #000;
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 2px;
}

.service-text p,
.service-text h3 {
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.serviceImg:hover .service-text p,
.serviceImg:hover .service-text h3 {
  color: #fff;
}

.service-text .btn-border {
  border-width: 1px;
  margin-top: 15px;
}

.serviceImg:hover .btn-border {
  color: #fff;
  border-color: #fff;
}

.service-page-area {
  padding-top: 70px;
  padding-bottom: 100px;
}

.service-page-area .service-box {
  margin-top: 30px;
  margin-bottom: 0;
}
.requirements ol li {
  list-style: decimal;
  /* list-style-type: decimal; */
}
/*Responsive*/
@media (max-width: 767px) {
  .service-area {
    padding-top: 0;
  }
  .service-page-area {
    padding-top: 70px;
  }
  .service-text h3 {
    font-size: 30px;
  }
}
