/* ===================== PAGE TITLE ======================*/
.breadcrumb-area {
  padding-top: 300px;
  padding-bottom: 200px;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.breadcrumb-area:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: #02050b; */
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  z-index: -1;
}

.title-text {
  position: absolute;
  width: 40%;
  left: 20%;

  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 30px 20px;
  padding-left: 80px;
  background: url("../../img/46.png") no-repeat;
  bottom: -20px;
  text-align: center;
}

[dir="rtl"] .title-text {
  position: absolute;
  width: 60%;
  left: 90%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding-top: 60px;
  /* padding-right: 50px; */
  background: url("../../img/45.png") no-repeat;
  bottom: -20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

[dir="rtl"] .title-text h1 {
  margin-right: 50%;
  font-size: 1.625rem;
}
.title-text h1 {
  color: #fff;
  margin: 0;
  /* letter-spacing: 2px; */
  font-size: 34px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 65px;
  padding: 0 10%;
  text-align: start;
}

.breadcrumb-inn {
  padding-left: 60px;
}

.breadcrumb-inn h1 {
  color: #fff;
  font-size: 75px;
  /* letter-spacing: 6px; */
  padding: 0 10%;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 65px;
  margin-bottom: 15px;
}

.breadcrumb-inn ul li {
  list-style: none;
  position: relative;
  display: inline-block;
  color: #fff;
  padding-right: 45px;
  margin-right: 10px;
  text-transform: capitalize;
  font-family: "Teko", sans-serif;
  /* letter-spacing: 3px; */
  font-size: 22px;
}

.breadcrumb-inn ul li:last-child {
  padding: 0px;
  margin: 0px;
}

.breadcrumb-inn ul li:before {
  position: absolute;
  content: "//";
  top: 0px;
  right: 0px;
}

.breadcrumb-inn ul li:last-child:before {
  display: none;
}

.breadcrumb-inn ul li a {
  color: #fff;
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 33px;
  border: 1px solid #c1952b;
  font-size: 16px;
}

/*Responsive*/
@media (max-width: 991px) {
  .breadcrumb-area .hero-social p {
    display: none;
  }
}
@media (max-width: 767px) {
  .breadcrumb-inn h1 {
    font-size: 50px;
    /* letter-spacing: 4px; */
    line-height: 55px;
  }
  .breadcrumb-area {
    padding-top: 200px;
    padding-bottom: 150px;
  }
  .breadcrumb-inn {
    padding-left: 60px;
  }
}
@media (width: 1024px) {
  .breadcrumb-inn {
    padding-left: 60px;
  }
}

@media (max-width: 767px) {
  [dir="rtl"] .title-text {
    position: absolute;
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 1500px) {
  [dir="rtl"] .title-text {
    position: absolute;
    width: 50%;
    left: 90%;
  }
}

@media (min-width: 1730px) {
  [dir="rtl"] .title-text {
    position: absolute;
    width: 45%;
    left: 95%;
  }
}

@media (min-width: 2200px) {
  [dir="rtl"] .title-text {
    position: absolute;
    width: 40%;
    left: 97%;
  }
}

@media (min-width: 2650px) {
  [dir="rtl"] .title-text {
    position: absolute;
    width: 35%;
    left: 97%;
  }
}

@media (min-width: 2800px) {
  [dir="rtl"] .title-text {
    position: absolute;
    width: 25%;
    left: 95%;
  }
}

@media (min-width: 3500px) {
  [dir="rtl"] .title-text {
    position: absolute;
    width: 20%;
    left: 95%;
  }
}

@media (min-width: 4000px) {
  [dir="rtl"] .title-text {
    position: absolute;
    width: 20%;
    left: 98%;
  }
}

@media (min-width: 5000px) {
  [dir="rtl"] .title-text {
    position: absolute;
    width: 15%;
    left: 99%;
  }
}

@media (min-width: 6500px) {
  [dir="rtl"] .title-text {
    position: absolute;
    width: 12%;
    left: 99%;
  }
}
