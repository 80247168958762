/* ===================== HERO ======================*/
.hero-area {
  padding: 0;
  position: relative;
}

.hero-area .hero-slide-item {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-size: cover;
}

.hero-area .owl-item {
  overflow: hidden;
}

.hero-area .hero-slide-item:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: #eee; */
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  z-index: -1;
}
.slick-slide .hero-slide-item .container,
.slick-current .hero-slide-item .container,
.slick-active .hero-slide-item .container {
  animation: mymove 2s;
}
/* .hero-slide-item-left:after {
  left: 0;
}

.hero-slide-item-right:after {
  right: 0;
} */

.hero-slide-item .container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@keyframes mymove {
  from {
    top: 80%;
  }
  to {
    top: 50%;
  }
}
/* .slick-track {
  width: 100% !important;
  height: 10% !important;
} */
[dir="rtl"] .slick-slide {
  float: left;
}
/* .hero-slide-item-left .container {
  left: 50%;
}

.hero-slide-item-right .container {
  right: 50%;
} */

.hero-slide-item .hero-text {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: right;
  padding: 0 5%;
  padding-top: 60px;
  color: #3a3a3c;
}

[dir="rtl"] .hero-text {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.hero-text h2 {
  color: #fff;
  padding: 20px;
  background-color: rgba(58, 58, 60, 0.4);
  font-size: 42px;
  width: 50%;
  text-transform: uppercase;
  font-weight: 600;
}

.hero-action {
  margin-top: 30px;
}

.hero-text .btn-border {
  border-color: #fff;
  color: #fff;
  margin-left: 20px;
}

[dir="rtl"] .hero-text .btn-border {
  margin-left: 0;
  margin-right: 20px;
}

.hero-text .cta-btn:hover {
  color: #fff;
}

.hero-text .btn-border:hover {
  background: #c1952b none repeat scroll 0 0 !important;
  border-color: #c1952b;
}

.hero-slide-item .video-main {
  position: absolute;
  left: 25%;
  bottom: 30%;
}

/* .hero-slide-item-left .video-main {
  left: 25%;
}

.hero-slide-item-right .video-main {
  right: 25%;
} */

.video-main {
  position: relative;
  display: inline-block;
}

.video {
  height: 65px;
  width: 65px;
  line-height: 65px;
  text-align: center;
  border-radius: 100%;
  background: transparent;
  color: #c1952b;
  display: inline-block;
  background: #fff;
  z-index: 999;
  font-size: 20px;
}

@-webkit-keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.fa-play:before {
  content: "\f04b";
}

.waves {
  position: absolute;
  width: 165px;
  height: 165px;
  background: rgba(183, 141, 101, 0.3);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 100%;
  right: -50px;
  bottom: -50px;
  z-index: -1;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.hero-slider .hero_arrows {
  padding-left: 10%;
  position: absolute;
  bottom: 30px;
  z-index: 11;
}

[dir="rtl"] .hero-slider .hero_arrows {
  padding-left: 10%;
  float: left;
  position: absolute;
  bottom: 30px;
  z-index: 11;
  text-align: left;
}

/* .hero-slider-left .hero_arrows {
  padding-left: 10%;
}

.hero-slider-right .hero_arrows {
  padding-right: 10%;
} */

.hero-slider .hero_arrows button {
  width: 50px;
  height: 50px;
  border: 1px solid #737373 !important;
  color: #737373 !important;
  margin-right: 10px;
  border-radius: 50%;
  font-size: 20px !important;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.hero-slider .hero_arrows button:hover {
  border: 1px solid #fff !important;
  color: #fff !important;
}

/* Responsive */
@media (max-width: 991px) {
  .hero-social {
    width: 60px;
  }
  .hero-social ul li a {
    font-size: 14px;
  }
  .hero-social ul li {
    margin-top: 20px;
  }
  .hero-social p {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .hero-text h2 {
    font-size: 40px;
    width: 100%;
    /* letter-spacing: 3px; */
  }
  .hero-action .cta-btn {
    display: inline-block;
    padding: 14px 17px !important;
    text-align: center;
    margin: 3px;
  }
  .hero-slide-item .video-main {
    display: none;
  }
  .section-title {
    font-size: 38px;
    line-height: 44px;
  }
  .sub-title {
    /* letter-spacing: 3px; */
    font-size: 20px;
  }
  .hero-social p {
    display: none;
  }
  .hero-slider .hero_arrows {
    padding-left: 60px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 20px;
  }
  /* .hero-slider-left .hero_arrows {
    padding-left: 60px;
    left: 50%;
  }
  .hero-slider-right .hero_arrows {
    padding-right: 60px;
    right: 50%;
  } */
  .hero-slide-item .hero-text {
    padding-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-area .hero-slide-item {
    height: 600px;
  }
  .hero-slide-item .hero-text {
    padding-top: 0;
  }
  .hero-text h2 {
    font-size: 56px;
    width: 75%;
    /* letter-spacing: 2px; */
  }
  .section-title {
    font-size: 45px;
    line-height: 54px;
  }
}

@media (width: 1024px) {
  .hero-slider .owl-nav {
    padding-left: 15%;
  }
  /* .hero-slider-right .owl-nav {
    padding-right: 15%;
  } */
  .section-title {
    font-size: 50px;
    /* letter-spacing: 2px; */
    line-height: 55px;
  }
}
