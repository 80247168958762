/* ===================== PROJECT ======================*/
.projects-area {
  padding-bottom: 70px;
}
/* .project-box {
  display: flex;
  flex-direction: column;
  justify-content: ;
} */
.project-box a {
  display: block;
}

.project-box .onlyName {
  display: block;
  background-color: rgba(193, 149, 43, 0.7);
}
.project-box .onlyName h3 {
  background-color: rgba(193, 149, 43, 0.7);
}
.project-box .fullText {
  display: none;
}

.project-box:hover .onlyName {
  display: none;
}
.project-box:hover .fullText {
  display: inline-block;
  width: 100%;
  background: rgba(193, 149, 43, 0.7);
}
.project-box:hover .fullText h3 {
  font-size: 30px;
  top: 20%;
}
.service-box {
  text-align: center;
  padding: 50px 0;
  position: relative;
  z-index: 1;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  height: 400px;
  width: 400px;
  word-break: break-all;
}

/* .service-box:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(193, 149, 43, 0.3);
  z-index: -1;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
} */

.service-box > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

/* .service-box:hover:after, */
.service-box:hover > img {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.service-box-modified > .landmarkImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.service-text {
  padding-top: 60%;
  margin-top: 30px;
  word-break: break-all;
}

.service-box-modified .service-text h3 {
  color: #fff;
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 2px;
}

.service-text p,
.service-text h3 {
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.service-box-modified .service-text p {
  opacity: 0;
}
.service-box:hover .service-text {
  padding-top: 0;
  margin-top: 0;
}
.service-box:hover .service-text p,
.service-box:hover .service-text h3 {
  background: none;
  opacity: 1;
  color: #fff;
}

.service-text .btn-border {
  border-width: 1px;
  margin-top: 15px;
}

.service-box:hover .btn-border {
  color: #fff;
  border-color: #fff;
}

.service-page-area {
  padding-top: 70px;
  padding-bottom: 100px;
}

.service-page-area .service-box {
  margin-top: 30px;
  margin-bottom: 0;
}

.project-img img {
  width: 100%;
}

/* .project-box:before {
  content: "";
  display: block;
  position: absolute;
  height: 0%;
  width: 100%;
  top: 0;
  -webkit-transition: height 0.5s ease-out;
  transition: height 0.5s ease-out;
  background: -webkit-linear-gradient(
    230deg,
    rgba(0, 0, 0, 0.2) 0%,
    #000000 100%
  );
  background: linear-gradient(-140deg, rgba(0, 0, 0, 0.2) 0%, #000000 100%);
  left: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  z-index: -1;
  border-radius: 5px;
} */

/* .project-img:after {
  background: -webkit-linear-gradient(top, transparent 0%, black 100%);
  background: linear-gradient(to bottom, transparent 0%, black 100%);
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  border-radius: 5px;
  -webkit-transition: height 0.2s ease-out;
  transition: height 0.2s ease-out;
}

.project-img:before {
  background: -webkit-linear-gradient(top, transparent 0%, #000000 100%);
  background: linear-gradient(to bottom, transparent 0%, #000000 100%);
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  border-radius: 5px;
  -webkit-transition: height 0.5s ease-out;
  transition: height 0.5s ease-out;
}

.project-box:hover:before {
  height: 100%;
}
.project-box:hover .project-img:after {
  height: 0;
}

.project-box:hover .project-img:before {
  height: 40%;
} */
/*Responsive*/
@media (max-width: 767px) {
  .project-box p {
    font-size: 14px;
    left: -110px;
    /* bottom: 135px; */
    /* letter-spacing: 4px; */
  }
  .project-box h3 {
    top: 0px;
    /* left: 20px; */
    /* letter-spacing: 2px; */
    font-size: 19px;
  }

  .project-box:hover .project-zoom {
    -webkit-transform: scale(1) translateX(-50%, -50%);
    transform: scale(1) translateX(-50%, -50%);
  }
  .projects-area {
    padding-bottom: 70px;
  }
  .projects-page-area .col-md-5:nth-child(2n + 2) {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  .projects-page-area .project-box {
    margin-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .projects-page-area .col-md-5:nth-child(2n + 2) {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  .projects-page-area .project-box {
    margin-bottom: 30px;
  }
  .projects-page-area {
    padding-bottom: 70px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .project-box p {
    font-size: 16px;
    left: -110px;
    /* bottom: 150px; */
    /* letter-spacing: 6px; */
  }
  .project-box h3 {
    top: 0px;
    /* left: 20px; */
    /* letter-spacing: 2px; */
    font-size: 24px;
  }

  .project-box:hover .project-zoom {
    -webkit-transform: scale(1) translateX(-50%, -50%);
    transform: scale(1) translateX(-50%, -50%);
  }
  .projects-area {
    padding-bottom: 70px;
  }
}
@media (width: 1024px) {
  .project-box p {
    font-size: 20px;
    left: -110px;
    /* bottom: 150px; */
    /* letter-spacing: 6px; */
  }
  .project-box h3 {
    top: 0px;
    /* left: 20px; */
    /* letter-spacing: 2px; */
    font-size: 30px;
  }

  .project-box:hover .project-zoom {
    -webkit-transform: scale(1) translateX(-50%, -50%);
    transform: scale(1) translateX(-50%, -50%);
  }
}
