/* ===================== PRICING ======================*/
.pricing-area {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
}

.pricing-area:before {
  position: absolute;
  content: "";
  background: #02050b;
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.pricing-area .section-title {
  color: #fff;
}

.pricing-box {
  background: #fff;
  padding: 30px;
  margin-top: 30px;
}

.pricing-header .price {
  color: #c1952b;
  font-weight: 500;
  font-size: 20px;
}

.pricing-header .price span {
  color: #111;
}

.pricing-content ul {
  margin: 30px 0;
}

.pricing-content ul li {
  margin: 5px 0;
}

.pricing-content ul li p {
  font-size: 16px;
  color: #111;
}

.pricing-content ul li p i {
  color: #c1952b;
  margin-right: 10px;
}

.pricing-content .hidden-list {
  text-decoration: line-through;
  color: #333;
}

.pricing-action a {
  display: inline-block;
  border-bottom: 1px solid #c1952b;
  font-weight: 700;
  font-size: 18px;
}
