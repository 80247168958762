/* ===================== BLOG ======================*/
.news-box {
  display: block;
  margin-top: 30px;
  position: relative;
}

.news-img img {
  width: 100%;
}

.news-text {
  position: absolute;
  width: 90%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 30px 20px;
  background: #c1952b;
  bottom: -50px;
  text-align: center;
}

.news-date {
  color: #9c9c9c;
  letter-spacing: 2px;
  font-weight: 600;
}

.news-text h3 {
  color: #fff;
  margin: 0;
  letter-spacing: 2px;
  font-size: 34px;
}

.news_more {
  margin-top: 100px;
}

.latest-news-area .row .col-md-6:last-child {
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
}
.blog-left .news-box {
  margin-top: 0;
  margin-bottom: 100px;
}
.blog-comment-area h3,
.blog-leave-comment h3,
.blog-left-content-share h3 {
  margin-bottom: 20px;
}

.blog-search-box form {
  position: relative;
}

.blog-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 30px 0;
}

.b_info_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-left-content h2 {
  margin-bottom: 10px;
  font-size: 50px;
}

.blog-info-img {
  width: 50px;
  background: #111;
  border: 1px solid #111;
}

.blog-info p {
  font-size: 16px;
}

.blog-info p i {
  margin-right: 10px;
  color: #c1952b;
}

.blog-info-date {
  margin: 0 20px;
}

.blog-left-content-share {
  text-align: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
}

.blog-left-content-share ul li {
  display: inline-block;
  margin: 0 3px;
}

.blog-left-content-share ul li button {
  display: block !important;
  background: #111 !important;
  color: #fff !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}

.blog-left-related-post {
  margin-top: 50px;
}

.blog-left-related-post .news-img {
  margin-top: 20px;
}

.blog-left-related-post .news-text {
  width: 80%;
  padding: 10px;
}

.blog-left-related-post .news-text h3 {
  font-size: 22px;
}

.blog-left-related-post .news-date {
  font-size: 15px;
}

.blog-comment-box {
  margin-left: 100px;
  position: relative;
  margin-bottom: 20px;
}

.blog-comment-people {
  width: 80px;
  position: absolute;
  left: -100px;
}

.blog-comment-people img {
  border-radius: 50%;
}

.blog-comment-text {
  background: #eef3fa;
  padding: 30px;
  border-radius: 30px;
  position: relative;
}

.blog-replay-box {
  margin-left: 200px;
}

.blog-comment-text:before {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  display: block;
  border-style: solid;
  top: 25px;
  border-width: 11px 13px 11px 0;
  border-color: transparent #eef3fa;
  left: -12px;
}

.blog-comment-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-comment-date p {
  margin-bottom: 15px;
  color: #111;
}

.blog-comment-date p i {
  margin-right: 10px;
  color: #c1952b;
}

.blog-leave-comment {
  margin-top: 50px;
}

.comment-form-group {
  position: relative;
  width: 100%;
}

.comment-form-group input {
  background: #eef3fa;
  padding: 0px 10px 0px 40px;
}

.comment-form-text textarea {
  background: #eef3fa;
  height: 200px;
  margin-top: 20px;
  padding: 20px;
}

.comment-form-group i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 10px;
}

.blog-comment-submit {
  text-align: center;
  margin-top: 50px;
}

.blog-left-content-share ul li button:hover {
  background-color: #c1952b !important;
}

/*Responsive*/
@media (max-width: 767px) {
  .latest-news-area .row .col-lg-6:last-child {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    margin-top: 60px;
  }
  .news-date {
    letter-spacing: 2px;
    font-size: 14px;
  }
  .news-text h3 {
    letter-spacing: 2px;
    font-size: 22px;
  }
  .latest-news-area .row .col-md-6:last-child {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    margin-top: 50px;
  }
  .comment-form-group {
    margin-top: 10px;
  }
}
@media (max-width: 575px) {
  .blog-info {
    display: block;
  }
  .blog-info-img {
    margin-bottom: 10px;
  }
  .b_info_flex {
    display: block;
  }
  .blog-info-date {
    margin: 0;
  }
  .blog-replay-box {
    margin-left: 100px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .news-text h3 {
    letter-spacing: 2px;
    font-size: 22px;
  }
  .news-date {
    font-size: 16px;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
  .news-text {
    padding: 15px;
  }
  .latest-news-area .row .col-md-6:last-child {
    -webkit-transform: translateY(-55px);
    transform: translateY(-55px);
  }
  .blog-replay-box {
    margin-left: 130px;
  }
  .comment-form-group {
    margin-top: 10px;
  }
}
@media (width: 1024px) {
  .project-box p {
    font-size: 20px;
    left: -110px;
    background: rgba(193, 149, 43, 0.3);
    /* bottom: 150px;
    letter-spacing: 6px; */
  }
  .project-box h3 {
    top: 15px;
    left: 20px;
    /* letter-spacing: 2px; */
    font-size: 30px;
  }

  /* .project-box:hover .project-zoom {
    -webkit-transform: scale(1) translateX(-50%, -50%);
    transform: scale(1) translateX(-50%, -50%);
  } */
}
