/* ===================== PORTFOLIO ======================*/
.no-paading {
	padding: 0
}

.portfolio-box {
	position: relative;
	overflow: hidden;
}

.portfolio-box .portfolio-text h2,
.portfolio-box .portfolio-text h3 {
	color: #fff
}

.portfolio-box:before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background: #02050b;
	opacity: 0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	left: 0;
	bottom: -100%;
	-webkit-transition: all 0.6s ease;
	transition: all 0.6s ease
}

.portfolio-text {
	position: absolute;
	bottom: 100%;
	left: 0;
	right: 0;
	width: 80%;
	margin: 0 auto;
	background: rgba(255, 255, 255, 0.35);
	padding: 20px 30px;
	-webkit-transition: all 0.6s ease;
	transition: all 0.6s ease;
}

.portfolio-box:hover:before {
	bottom: 0;
}

.portfolio-box:hover .portfolio-text {
	bottom: 50%;
	-webkit-transform: translateY(50%);
	transform: translateY(50%)
}
/*responsive*/
@media(max-width: 991px) {
	.portfolio-box {
		margin-top: 30px
	}
	.portfolio-page {
		padding-top: 70px
	}
	.no-paading {
		padding: 0 15px;
	}
}

@media(max-width: 767px){
	.portfolio-page .col-md-4:last-child {
		display: none
	}
}